import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {connectToTranslations} from '../IntlWrapper';
import {enUS, esMX, frCA} from '../../helpers/locale';
import {getImagePath} from '../../helpers/image-helper';

const locales = [enUS, frCA, esMX];

const Controls = ({showTranslations, changeLanguage, intl}) => {
  let languageSelector = showTranslations ? <li>
    <select id="iframe-language-select" value={intl.locale} onChange={e => {
      const locale = locales.find(x => x.long === e.target.value);

      changeLanguage(locale);
    }}>
      {locales.map(x => <option key={x.long} value={x.long}>{x.name}</option>)}
    </select>
  </li> : null;

  return (
    <div className="controls">
      <ul>
        {languageSelector}
        <li>
          <button onClick={window.print}>
            <img alt={intl.formatMessage({id: 'general.print'})} src={getImagePath({locale: intl.locale, folder: '', file: 'print.png'})}/>
            <FormattedMessage id='general.print' />
          </button>
        </li>
      </ul>
    </div>
  );
};

Controls.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  showTranslations: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default connectToTranslations(injectIntl(Controls));
