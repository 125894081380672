import React from 'react';
import IntlWrapper from './components/IntlWrapper';
import Base from './base';

import moment from 'moment';
import 'moment/locale/fr-ca';
import 'moment/locale/es-mx';
moment.locale('en');


const Layout = ({language, ...props}) => <IntlWrapper defaultLocale={language}>
  <Base {...props} />
</IntlWrapper>;

export default Layout;
