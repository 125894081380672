import React from 'react';
import PropTypes from 'prop-types';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import es from 'react-intl/locale-data/es';

import {IntlProvider, addLocaleData} from 'react-intl';
import moment from 'moment';
import qwest from 'qwest';
import path from 'path';
import {debugComponent} from '../helpers/debug';

import * as locales from '../helpers/locale';
import {getTokenData} from '../helpers/data-helper';

addLocaleData([...en, ...fr, ...es]);

class IntlWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    defaultLocale: PropTypes.string,
  };

  static childContextTypes = {
    changeLanguage: PropTypes.func.isRequired,
    formatPrintFile: PropTypes.func.isRequired,
  };

  state = {
    locale: undefined,
    allMessages: {},
  };

  getChildContext = () => ({
    changeLanguage: this.changeLanguage,
    formatPrintFile: this.formatPrintFile,
  });

  componentWillMount() {
    const token = getTokenData();
    const defaultLocale = token.Locale || this.props.defaultLocale;
    let locale = locales.enUS;

    if (defaultLocale) {
      locale = Object.values(locales).find(x => x.long === defaultLocale) || locale;
    }

    this.fetchCopy(locale)
      .then(messages => {
        const {allMessages} = this.state;

        allMessages[locale.long] = messages;
        moment.locale(locale.long.toLowerCase());
        this.setState({allMessages, locale});
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.defaultLocale &&
      nextProps.defaultLocale !== this.props.defaultLocale &&
      nextProps.defaultLocale !== this.state.locale.long) {
      this.changeLanguage(Object.values(locales).find(x => x.long === nextProps.defaultLocale));
    }
  }

  fetchCopy = locale => {
    console.log('Fetching copy');
    const allMessages = this.state.allMessages;

    return new Promise((resolve, reject) => {
      if (allMessages && allMessages[locale.long]) {
        resolve(allMessages[locale.long]);
        return;
      }

      qwest.get((window.canopyURL || '') + path.join('/Content/copy/languages', `${locale.long}.json?v=${window.dateStamp || 1}`), null, {
        headers: {},
        cache: true,
      })
        .then((xhr, res) => res)
        .then(messages => resolve(messages))
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  };

  changeLanguage = locale => {
    if (locale === this.state.locale) {
      return;
    }

    this.fetchCopy(locale)
      .then(messages => {
        const {allMessages} = this.state;

        allMessages[locale.long] = messages;

        moment.locale(locale.long.toLowerCase());

        this.setState({
          locale,
          allMessages,
        });
      })
      .catch(err => {
        console.error('Failed to change locale.', err);
      });
  };

  formatPrintFile = pf => {
    pf = pf || {};

    const locale = this.state.locale;

    return pf[locale.long] || pf[locale.short] || '';
  };

  render() {
    console.log('Starting render');
    const {locale, allMessages} = this.state;

    if (!locale) {
      console.log('no locale yet');
      return null;
    }

    const messages = allMessages[locale.long];

    if (!messages || !Object.keys(messages).length) {
      console.log('no messages', messages);
      return null;
    }

    return <IntlProvider key={locale.long}
                         locale={locale.long}
                         messages={messages}>
      {this.props.children}
    </IntlProvider>;
  }
}

export function connectToTranslations(C) {
  return class Wrapped extends React.Component {
    static displayName = `connectChangeLanguage(${C.displayName || C.name})`;

    static contextTypes = {
      changeLanguage: PropTypes.func.isRequired,
      formatPrintFile: PropTypes.func.isRequired,
    };

    render() {
      return <C {...this.props}
                changeLanguage={this.context.changeLanguage}
                formatPrintFile={this.context.formatPrintFile} />;
    }
  };
}


export default debugComponent(IntlWrapper);
