import React from 'react';
import PropTypes from 'prop-types';
import {getData} from './helpers/data-helper';
import Controls from './components/controls/controls.jsx';
import Header from './components/header/header.jsx';
import TruBlue from './components/trublue/trublue.jsx';
import RegularMaintenance from './components/regular-maintenance/regular-maintenance.jsx';
import ShoppingList from './components/shopping-list/shopping-list.jsx';
import MaintenanceSchedule from './components/maintenance-schedule/maintenance-schedule.jsx';
import WaterQualityScore from './components/water-quality-score/water-quality-score.jsx';
import ProblemsSolutions from './components/problems-solutions/problems-solutions.jsx';
import Congratulations from './components/congratulations/congratulations.jsx';
import SuggestedProducts from './components/suggested-products';
import Footer from './components/footer/footer.jsx';
import Established from './components/established/established.jsx';
import ReportHelper from './helpers/report-helper.jsx';
import SectionHelper from './helpers/section-helper.jsx';
import Announcement from './components/announcements/announcement.jsx';

class Base extends React.Component {
  static propTypes = {
    showControls: PropTypes.bool,
    version: PropTypes.number,
    data: PropTypes.object,
  };

  static defaultProps = {
    showControls: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data || undefined,
      showTranslations: props.version && props.version > 1,
      updated: false,
      helitackView: false,
      version: props.version || 1,
    };
  };

  componentWillMount() {
    console.log('Mounting base component');

    if (this.props.data) {
      return;
    }

    getData()
      .then((xhr, response) => {
        response.analysisObj.SpecialReportType = response.specialReportType !== undefined ?
          response.specialReportType :
          null;

        this.setState({
          data: response.analysisObj,
          showTranslations: response.hasTrasnlations && response.version && response.version > 1,
          helitackView: response.helitackView,
          version: response.version || 1,
        });

        if(response.helitackView) {
          document.getElementById('report-viewer-iframe').classList.add('horizontal-scroll');
        }
      })
      .catch(err => {
        console.log('Failed to retrieve data.', err);
      });
  }

  render() {
    const {data, showTranslations, helitackView} = this.state;
    const {showControls} = this.props;
    const {ReportType, SpecialReportType, isDealerTruBlue, hideTruBlueBanner, hideOnWinterClosing} = data || {};

    if (!data) {
      return null;
    }
    
    return <div id='bl-report-viewer' className='report bl-report-viewer'>
      {
        showControls &&
        <Controls showTranslations={showTranslations}/>
      }

      {
        SectionHelper.showHeader(ReportType, SpecialReportType) &&
        <Header data={ReportHelper.getHeaderData(data)}/>
      }

      {
        SectionHelper.showShoppingList(ReportType) &&
        <ShoppingList data={ReportHelper.GetShoppingListData(data)}/>
      }

      {
        SectionHelper.showTruBlueBanner(isDealerTruBlue, hideTruBlueBanner) &&
        <TruBlue data={ReportHelper.getTruBlueData(data)}/>
      }

      {
        SectionHelper.showRegularMaintenance(ReportType, SpecialReportType, hideOnWinterClosing) &&
        <RegularMaintenance {...ReportHelper.getMaintenanceData(data)} country={data.country} />
      }

      {
        SectionHelper.showWaterQualityScore(ReportType, SpecialReportType) &&
        <WaterQualityScore data={ReportHelper.getWaterQualityScoreData(data)}/>
      }

      {
        SectionHelper.showProblemSolutions(ReportType, data.problemAreas) ?
          <ProblemsSolutions data={ReportHelper.getProblemAreas(data)} country={data.country}/> :
          SectionHelper.showCongratulations(ReportType, data.problemAreas) &&
          <Congratulations data={ReportHelper.getCongratulationsData(data)}/>
      }

      {
        SectionHelper.showSuggestedProducts(ReportType, data.suggestedProducts) &&
          <SuggestedProducts {...ReportHelper.getSuggestedProducts(data)} />
      }

      {
        <Announcement data={ReportHelper.getAnnouncements(data)} /> 
      }

      {
        SectionHelper.showMaintenanceSchedule(ReportType) &&
        <MaintenanceSchedule data={ReportHelper.getProblemAreas(data)} />
      }

      {
        SectionHelper.showFooter(ReportType, SpecialReportType) &&
        <Footer data={ReportHelper.getFooterData(data)}/>
      }

      {
        data.established &&
        <Established date={data.established}/>
      }

    </div>;
  }
}

export default Base;
